import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { convertTo2Digits } from "../../utils/UtilsFunctions";
import Url from "../../url/Url";

export const getRequest = async (page, filter) => {
  let responseData = {};
  //const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_HOLIDAY(page, Url.registration_x_page))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_management_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_HOLIDAY_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_findid_error);
    });
  return responseData;
};

export const createRequest = async (data) => {
  let responseData = {};
  const requestBody = new URLSearchParams([
    ["day", Number(convertTo2Digits(data.dateH.getDate()))],
    ["month", Number(convertTo2Digits(data.dateH.getMonth() + 1))],
    ["year", data.dateH.getFullYear()],
    ["description", data.description],
  ]);
  await privateFetch
    .post(`${URL.REQUEST_HOLIDAY_CREATE(null)}?${requestBody}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_holiday_save_error);
    });
  return responseData;
};

export const updateRequest = async (data) => {
  let responseData = {};
  const requestBody = new URLSearchParams([
    ["id", data.id],
    ["day", Number(convertTo2Digits(data.dateH.getDate()))],
    ["month", Number(convertTo2Digits(data.dateH.getMonth() + 1))],
    ["year", data.dateH.getFullYear()],
    ["description", data.description ? data.description : ""],
  ]);
  await privateFetch
    .put(`${URL.REQUEST_HOLIDAY_CREATE(null)}?${requestBody}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const deleteRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_HOLIDAY_ID(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_delete_error);
    });
  return responseData;
};

import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import Url from "../../url/Url";

export const getRequest = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_USER_LIST(page, Url.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_management_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_findid_error);
    });
  return responseData;
};

export const createRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: data.userRole?.id,
    password: data.password,
  };
  await privateFetch
    .post(URL.REQUEST_USER_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updateRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.userRole?.id,
    password: data.password,
  };
  await privateFetch
    .put(URL.REQUEST_USER(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updatePassRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.roleID?.id,
    password: data.password,
  };
  await privateFetch
    .put(URL.REQUEST_USER(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      console.log(error);
    });
  return responseData;
};

export const deleteRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_USER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_delete_error);
    });
  return responseData;
};

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 14/01/2023
 *
 * @description JS para manejo de labels
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General label */
  com_parval_label_yes: "Si",
  com_parval_label_not: "No",
  com_parval_label_add_subtitle: "Ingrese los datos indicados",
  com_parval_label_update_subtitle: "Modifique los datos indicados",
  com_parval_label_code: "Código",
  com_parval_label_codetfa: "Código TFA",
  com_parval_label_phone: "Teléfono",
  com_parval_label_url: "Url",
  com_parval_label_category: "Categoría",
  com_parval_label_name: "Nombre",
  com_parval_label_lastname: "Apellido",
  com_parval_label_state: "Estado",
  com_parval_label_role: "Perfil",
  com_parval_label_createat: "Fecha de creación",
  com_parval_label_email: "Correo electrónico",
  com_parval_label_password: "Contraseña",
  com_parval_label_passwordrepeat: "Repita contraseña",
  com_parval_label_id: "ID",
  com_parval_label_description: "Descripción",
  com_parval_label_price: "Precio",
  com_parval_label_tax: "ITBIS (18%)",
  com_parval_label_taxes: "Impuesto",
  com_parval_label_department: "Departamento",
  com_parval_label_generationtime: "Tiempo generación",
  com_parval_label_comment: "Comentario",
  com_parval_label_free: "Gratis",
  com_parval_label_zero: "0",
  com_parval_label_select: "Seleccione",
  com_parval_label_select_other: "Otro",
  com_parval_label_not_apply: "No aplica",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght:string) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght:string) =>
    `Longitud minima permitida (${lenght})`,
  /** General sucess labels in process */
  com_parval_label_request_save: "Registro insertado con exito",
  com_parval_label_request_update: "Registro actualizado con exito",
  com_parval_label_request_delete: "Registro eliminado con exito",
  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

    /** Table label */
  com_parval_label_table_filter_title: "Filtrar",
  com_parval_label_table_filter_placeholder: "Filtro de búsqueda",
  com_parval_label_table_action: "Acciones",
  com_parval_label_table_empty: "No existen registros",

  /** General modal label  */
  com_parval_label_modal_delete_title: "Eliminar Registro",

  /** Header dashboard label */
  com_parval_label_dashboard_header_title: "Operaciones",
  /** Menu dashboard label */
  com_parval_label_dashboard_menu_principal: "Portal Administrativo",
  com_parval_label_dashboard_menu_inicio: "Inicio",
  com_parval_label_dashboard_menu_logout: "Cerrar sesión",  

  com_parval_label_forgotpass_general_sucess:
    "El cambio de contraseña se completo",
  
  com_parval_label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",
  com_parval_label_button_ok_title: "Ok",
  
  /** ActiveRegister */
  com_parval_label_activeregister_title: "Activación Usuario",
  /** ActiveRegister success*/
  com_parval_label_activeregister_general_sucess:
    "La activación del usuario se completó",
  com_parval_label_activeregister_general_sucess2:
    "La activación del usuario se completó correctamente.",
  /** ActiveRegister error*/
  com_parval_label_activeregister_general_error:
    "Error en proceso de activar usuario",

  /** Users */
  com_parval_label_user_button_add_title: "Agregar usuario",
  com_parval_label_user_add_title: "Agregar usuario",
  com_parval_label_user_update_title: "Editar usuario",
  com_parval_label_user_update_pass_title: "Actualizar contraseña de usuario",
  /** Role label */
  com_parval_label_user_button_add_role: "Agregar perfil",
  /** Email add */
  com_parval_label_user_button_add_emailprofile: "Agregar correo",
  /** Users service error label */
  com_parval_label_user_find_error: "Error en consulta de usuarios",
  com_parval_label_user_findid_error: "Error en consulta por id de usuario",
  com_parval_label_user_save_error: "Error en proceso de guardar usuario",
  com_parval_label_user_update_error: "Error en proceso de actualizar usuario",
  com_parval_label_user_delete_error: "Error en proceso de eliminar usuario",

  /** Holiday service error label */
  com_parval_label_holiday_update_error: "Error en proceso de actualizar feriado",
  com_parval_label_holiday_save_error: "Error feriado ya existe",
  com_parval_label_holiday_delete_error: "Error en proceso de eliminar feriado",
};

import { CONSTANT } from "./Constants";
export const URL = {

    /** HOME PATH */
    LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/login/twofactor",
    LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/autenticate",
    LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/recoverPasswordAdmin",
    LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/changePassword",
    LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/activate",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

    
    /** users urls */
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/{page}/{size}/?page=${page}&size=${size}`,
    REQUEST_USER_LIST_FILTER: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,


    /** holidays urls */
    REQUEST_HOLIDAY: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/holiday/${page ? page : '0'}/${size ? size : '10'}`,    
    REQUEST_HOLIDAY_CREATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/holiday/`,    
    REQUEST_HOLIDAY_ID: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/holiday/${id ? id : ''}`
}
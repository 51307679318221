import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes,
  defer,
  Navigate,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";
import UserManagement from "../pages/UserManagement";
import HolidayManager from "../pages/HolidayManager";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
const Session = lazy(() => import("../pages/session/Session"));

const NotFound = lazy(() => import("../pages/NotFound"));

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<DashboardHome />}>
        <Route index element={<UserManagement />} />
        <Route path="/HolidayManagement" element={<HolidayManager />} />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);

import { useContext } from "react";
import AppContext from "../contexts/AppContext";

/*****************************************************************************
 * REVISION 1.1
 * Fecha: 14/01/2023
 *
 * @description Hook para manejo de contexto de la aplicacion
 * @author luis.ferrer
 * @version 1.0
 *
 ****************************************************************************/
export const useApp = () => {
  return useContext(AppContext);
};

import { useState, createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { requestTypes } from "../types/Context";
import { setAuthData } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import Url from "../url/Url";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(authData != null);
  const navigate = useNavigate();

  const loginProvider = async (loginData: any) => {
    try {
      //save login data in storage
      const authData = await setAuthData("authSMAdminInfo", loginData);
      console.log(authData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        setAuthenticate(valid);
        setAuthInfo(authData);
        navigate("/", { replace: true });
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    e.preventDefault();
    await setAuthData("authSMAdminInfo", null);
    setAuthenticate(false);
    //@ts-ignore
    window.location.replace(Url.session_base_url);
  };

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
    }),
    // eslint-disable-next-line
    [authInfo, authenticate, isLoading, errorMsg, successMsg, modalData]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;

import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as logo } from "../../resources/images/logopv.svg";
import { ReactComponent as table } from "../../resources/icons/table.svg";
import { ReactComponent as list } from "../../resources/icons/list-check.svg";
import { ReactComponent as bracket } from "../../resources/icons/right-from-bracket.svg";
import { ReactComponent as house } from "../../resources/icons/house.svg";
import { ReactComponent as gear } from "../../resources/icons/user-gear.svg";
import { ReactComponent as usersGear } from "../../resources/icons/user-gear.svg";
import { ReactComponent as trash } from "../../resources/icons/trash.svg";
import { ReactComponent as sorter } from "../../resources/icons/sorter.svg";
import { ReactComponent as file } from "../../resources/icons/file-import.svg";
import { ReactComponent as plus } from "../../resources/icons/plus.svg";
import { ReactComponent as key } from "../../resources/icons/key.svg";
import { ReactComponent as pen } from "../../resources/icons/pen.svg";

export const Logo = () => {
  return (
    <SvgIcon
      component={logo}
      viewBox="0 0 360 360"
      sx={{ fontSize: "8rem", width: 150, mb: 4 }}
    />
  );
};

export const HouseIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={house}
      viewBox="0 0 576 512"
      sx={{ fontSize: ".8125rem", width: 15 }}
      {...props}
    />
  );
};
export const GearIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={gear}
      viewBox="0 0 640 512"
      sx={{ fontSize: ".8125rem", width: 15 }}
      {...props}
    />
  );
};
export const UsersGearIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={usersGear}
      viewBox="0 0 640 512"
      sx={{ fontSize: ".8125rem", width: 15 }}
      {...props}
    />
  );
};
export const TableIcon = () => {
  return (
    <SvgIcon
      component={table}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const ListCheckIcon = () => {
  return (
    <SvgIcon
      component={list}
      viewBox="0 0 576 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const FromBracketIcon = () => {
  return (
    <SvgIcon
      component={bracket}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const SortertIcon = () => {
  return (
    <SvgIcon
      component={sorter}
      viewBox="0 0 616.1 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const TrashIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={trash}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};

export const FileIcon = () => {
  return (
    <SvgIcon
      component={file}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const PlusIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={plus}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};

export const KeyIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={key}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};

export const PenIcon = ({ ...props }: any) => {
  return (
    <SvgIcon
      component={pen}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};

import { useState } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { formatDate } from "../../utils/UtilsFunctions";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateHoliday {
  id?: number;
  dateH: any;
  description: string;
}

interface DialogProps {
  data: any;
  allData: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateHolidayModal = ({
  data,
  allData,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const validation = Yup.object().shape({
    dateH: Yup.date().typeError("Campo es requerido"),
    description: Yup.string().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    dateH:
      data.day && data.month && data.year
        ? new Date(`${data.year}/${data.month}/${data.day}`)
        : null,
    description: data.description ? data.description : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateHoliday>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  function disableWeekends(date: any) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const addedDays = allData.content.map((d: any) =>
    formatDate(new Date(`${d.year}/${d.month}/${d.day}`))
  );

  const customDayRenderer = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const stringifiedDate = date.toISOString().slice(0, 10);
    if (addedDays.includes(stringifiedDate)) {
      return <PickersDay {...pickersDayProps} disabled />;
    }
    return <PickersDay {...pickersDayProps} />;
  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar feriado" : "Editar feriado"}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"dateH"}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider
                    dateAdapter={DateFnsUtils}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      label="Seleccione día feriado"
                      minDate={new Date()}
                      className={errors.dateH && "Mui-error"}
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 5)
                        )
                      }
                      renderDay={customDayRenderer}
                      shouldDisableDate={disableWeekends}
                      closeOnSelect={false}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          variant="standard"
                          sx={{ "& input": { paddingLeft: "0!important" } }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateHolidayModal;

import {
  UsersGearIcon,
  TableIcon,
  FromBracketIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "./Properties_es";

import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Usuarios",
    icon: (
      <UsersGearIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "/",
    permissions: ["is_superuser", "is_user"],
  },
  {
    label: "Días feriados",
    icon: <TableIcon />,
    to: "holidayManagement",
    permissions: ["is_superuser", "is_user"],
  },
  {
    label: properties.com_parval_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superuser", "is_user"],
  },
];

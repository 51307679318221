import React from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import { TrashIcon } from "../svgicons/SvgIcons";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));
const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "17px",
  padding: theme.spacing(4),
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  textChildren?: any;
  actionButton?: any;
}

const DeleteModal = ({ textChildren, actionButton }: DialogProps) => {
  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        <TrashIcon sx={{ fontSize: "1rem", width: 16, color: "#3e627c" }} />
        {properties.com_parval_label_modal_delete_title}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Text id="alert-dialog-description">
          ¿Está seguro de eliminar <strong>{textChildren}</strong>?
        </Text>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>{actionButton}</Actions>
    </>
  );
};

export default DeleteModal;

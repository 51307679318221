import { Stack, Typography, Pagination, useTheme } from "@mui/material";

interface TablesPaginationTypes {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: any;
  totalElements: any;
  totalPages: any;
}

const FancyTablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  totalElements,
  totalPages,
}: TablesPaginationTypes) => {
  const theme = useTheme();
  const initRow = page * rowsPerPage;
  const finalRow = initRow + rowsPerPage;
  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems="center"
      justifyContent="space-between"
      sx={{ pt: 6 }}
    >
      <Typography
        variant="body1"
        color="initial"
        sx={{
          fontFamily: "D-dinExp",
          color: theme.palette.greyDue.light,
          fontSize: "1rem",
          mb: {
            xs: 6,
            md: 0,
          },
          width: {
            xs: "100%",
            md: "50%",
          },
        }}
      >
        Mostrando {initRow + 1} a {finalRow} de {totalElements} registros
      </Typography>
      <Pagination
        count={totalPages}
        color="primary"
        onChange={onPageChange}
        showFirstButton
        showLastButton
      />
      {/* <TablePagination
        labelRowsPerPage={"Filas por páginas"}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      /> */}
    </Stack>
  );
};

export default FancyTablePagination;

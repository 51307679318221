import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  useTheme,
  Button,
  Divider,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import { useForm } from "react-hook-form";
import {
  getRequest,
  createRequest,
  updateRequest,
  deleteRequest,
  findByIdRequest,
} from "../services/holidays/HolidaysService";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import AddOrUpdateHolidayModal from "../components/utils/AddOrUpdateHolidayModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";

const HolidayManager = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [holidayData, setHolidayData] = useState<any>([]);

  const initForm = {
    id: 0,
    day: "",
    month: "",
    year: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit, formState } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   */
  const handleFetchData = async (currentPage: number) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage);
      let data = await getRequest(currentPage);
      if (data) {
        setHolidayData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let holidayDataID = await findByIdRequest(id);
      //@ts-ignore
      if (holidayDataID && holidayDataID?.id > 0) {
        //@ts-ignore
        setFormData(holidayDataID);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de agregar registro
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_holiday_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_holiday_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_holiday_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "passchange") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = holidayData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage);
    }
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
  };

  return (
    <>
      <Box
        sx={{
          p: {
            xs: 5,
            md: 12,
          },
          pb: {
            md: 6,
          },
        }}
      >
        <Typography
          variant="caption"
          color="info.dark"
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          Inicio{" "}
        </Typography>
        <Typography variant="caption" color="text.primary">
          {"/ "} Días feriados
        </Typography>
        <Divider />
        <Paper
          sx={{
            padding: {
              xs: 5,
              md: 8,
            },
            mt: {
              xs: 5,
              md: 10,
            },
            mb: 5,
          }}
        >
          <Grid container spacing={8} sx={{ pb: 8 }}>
            <Grid item md={9} sm={6} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar feriado
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="holiday table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">Feriado</TableCell>
                  <TableCell align="center">Descripción</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidayData &&
                  holidayData.content &&
                  holidayData.content.map((row: any, i: number) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        {row.day}/{row.month}/{row.year}
                      </TableCell>
                      <TableCell align="center">{row.description}</TableCell>
                      <TableCell align="center">
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FancyTablePagination
            count={
              holidayData?.content?.length > 0
                ? holidayData?.content?.length
                : 0
            }
            rowsPerPage={holidayData.size}
            page={page}
            onPageChange={handleChangePage}
            totalElements={holidayData.totalElements}
            totalPages={holidayData.totalPages}
          />
        </Paper>
      </Box>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
          maxWidth="xs"
        >
          <AddOrUpdateHolidayModal
            data={formData}
            allData={holidayData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={`${modalData?.modalObject?.day}/${modalData?.modalObject?.month}/${modalData?.modalObject?.year}`}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default HolidayManager;
